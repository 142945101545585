
export default class oauth2Service {
  login(provider) {
    return async (props) => {

    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    const code = params.get('code');

      const response = await fetch('/api/auth/loginOAuth2', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          code: code,
          provider: provider
        })
      });
    }
  }
}
