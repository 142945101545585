export default class institutionService {
  getInstitutionSystem() {
    return fetch('/api/institution/getInstitutionSystem', { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      if (response.redirected) {
        window.location.href = response.url;
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getInstitution() {
    return fetch('/api/institution/getInstitution', { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      if (response.redirected) {
        window.location.href = response.url;
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }
}