import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { getCookie } from 'react-use-cookie';

import AppContext from '../context/app'

import * as Sentry from '@sentry/browser';
import loginService from '../services/login';

export default function VerifyEmail(props) {
  const context = useContext(AppContext)
  const location = useLocation()
  const { verifyEmailToken } = useParams()

  useEffect( () => {
    window.scroll(0,0)
    async function manageTokens() {
        if (getCookie('clpsVerifyEmailUser')){
            const query = new URLSearchParams(location.search)
            window.location = `/${query.get('returnUrl')}`
            return
        }

        try {
            //if verify token here then call api to set cookie and redirect after
            if (verifyEmailToken){
                await new loginService().verifyEmailToken(verifyEmailToken)
                window.location = (new URLSearchParams(location.search)).get("returnUrl")
            }
            else{
                const query = new URLSearchParams(location.search)
                await new loginService().sendVerifyEmailToken(query.get('email'), query.get('returnUrl'))
            }
        }
        catch (err) {
            console.log(err);
            context.toastError('There was an error verifying your email')
            Sentry.captureException(err);
        }
    }
    manageTokens();
  },[])

  return (
    <div id='verifyEmail'>
        { !verifyEmailToken ?
            <div className='login-box'>
                <h2>Email Verification</h2>
                When accessing a link from a new computer you will be required to verify your email address.<br/>
                <br/>
                You will receive an email shortly to verify your email for this computer.  
            </div>
        : null }
    </div>
  )
}