import React from 'react'
import AppContext from '../context/app'

class Message extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {}
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(e) {
    this.context.removeToast()
  }

  render () {
    return (
     <div id="toast">
       { this.context.toast ?
          <div>
            { this.context.toast.type === 'success' ?
                <div className="success toast">
                    <div className="title"><i className='fa fa-check-circle'></i> Success</div>
                    <div className="message">{this.context.toast.message}</div>
                </div>
            : null }

            { this.context.toast.type === 'error' ?
                    <div className="error toast">
                    <div className="title"><i className='fa fa-exclamation'></i> Error</div>
                    <div className="message">{this.context.toast.message}</div>
                </div>
            : null }

            <div className="close-button" onClick={this.handleClose}>
                <i className='fa fa-times'></i>
            </div>
          </div>
        : null }
     </div>
    )
  }
}

export default Message;
