import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { getCookie } from 'react-use-cookie';

import AppContext from '../context/app'

import * as Sentry from '@sentry/browser';
import loginService from '../services/login';

export default function VerifyUser(props) {
  const context = useContext(AppContext)
  const location = useLocation()
  const { verifyUserToken } = useParams()
  let [ expiredToken, setExpiredToken ] = useState(false)

  useEffect( () => {
    window.scroll(0,0)
    async function manageTokens() {
        if (getCookie('clpsVerifyEmailUserId')){
            window.location = "/home"
            return
        }

        try {
            //if verify token here then call api to set cookie and redirect after
            if (verifyUserToken){
                let verifyTokenResult = await new loginService().verifyUserToken(verifyUserToken)
                if (verifyTokenResult.error === 'TokenExpiredError') {
                    const query = new URLSearchParams(location.search)
                    await new loginService().sendVerifyUserToken(query.get('returnUrl'))
                    setExpiredToken(true)
                }
                else
                    window.location = (new URLSearchParams(location.search)).get("returnUrl") || '/'
            }
            else{
                const query = new URLSearchParams(location.search)
                await new loginService().sendVerifyUserToken(query.get('returnUrl'))
            }
        }
        catch (err) {
            console.log(err);
            context.toastError('There was an error verifying your email')
            props.history.push('/login')
        }
    }
    manageTokens();
  },[])

  return (
    <div id='verifyEmail'>
        { !verifyUserToken ?
            <div className='login-box'>
                <h2>Email Verification</h2>
                When accessing a link from a new computer you will be required to verify your email address.<br/>
                <br/>
                You will receive an email shortly to verify your email for this computer.
            </div>
        : null }

        { expiredToken ?
            <div className='login-box'>
                <h2>Email Verification</h2>
                    The token has expired!<br/>
                    A new link has been sent.
            </div>
        : null }
    </div>
  )
}