import React from 'react'
import * as Sentry from '@sentry/browser';
import loginService from '../services/login';
import { Paper, Button, TextField, Icon } from '@mui/material';

import { createBrowserHistory as createHistory } from 'history'
import AppContext from '../context/app'

class ForgotPassword extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { email: '' }
    this.loginService = new loginService();
    this.handleChange = this.handleChange.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.history = createHistory();
  }

  componentDidMount(){
   
  }

  render () {
    return (
      <div id='forgotPassword'>
        <div className='headlineOne'>Forgot Password</div>
        <div className='box'>
          { !this.state.sendForgotPasswordLinkSuccess ?
            <div>
              <form onSubmit={this.handleForgotPassword}>
                  <Paper elevation={1}>
                    <div className="section">
                      <TextField className="input-field" name='email' type="email" maxLength="45" label="Email" placeholder="Email" value={this.state.email} required onChange={this.handleChange}/> 
                    </div>
                  </Paper>

                  <div className="actions">
                      <Button type="submit" variant="outlined"><Icon className="fa fa-envelope" /> Send Forget Password Email</Button>
                  </div>
              </form>

              { this.state.forgotPasswordError ?
                <div>
                  <div className="errorMessage">{this.state.forgotPasswordError}</div>
                </div>
              : null }
            </div>
          : 
            <div className="successMessage">An email has been sent to you with your forgot password link.</div>
          }
        </div>
      </div>
    )
  }

  handleChange(event) {
    let changeObject = {};
    changeObject[event.target.name] = event.target.value;
    this.setState(changeObject);
  }


  async handleForgotPassword(event) {
    event.preventDefault();

    try {
      let forgotPasswordResult = await this.loginService.forgotPassword(this.state.email)
      let forgotPasswordErrorMap = {
        userNotFound: 'Please contact your administrator to receive an invitation.'
      }

      //if user was found, login   
      if (!forgotPasswordResult.forgotPasswordError)
        this.setState({sendForgotPasswordLinkSuccess: true, forgotPasswordError: false});
      else {
        //there was an error
        let forgotPasswordError = forgotPasswordErrorMap[forgotPasswordResult.forgotPasswordError];
        this.setState({forgotPasswordError})
      }
    }
    catch(err) {
      console.log(err);
      this.context.toastError('There was an error sending your forgot password link')
      Sentry.captureException(err);
    }
  }

}

export default ForgotPassword
