import React from 'react'
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom'
import { createBrowserHistory as createHistory } from 'history'
import queryString from 'query-string'

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import AppContext from '../context/app'
import '../less/Header.less'

class Header extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { openSupportDialog: false }
    this.history = createHistory();
    this.handleClose = this.handleClose.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
  }

  nextStep(e) {
    e.preventDefault()
    window.scroll(0,0)
  }

  handleClickOpen = () => {
    this.setState({
      openSupportDialog: true,
    });
  };

  handleClose = () => {
    this.setState({ openSupportDialog: false });
  };

  componentDidMount(){
    let query = queryString.parse(this.history.location.search)
    if (query.help)
      this.setState({ openSupportDialog: true,});
  }

  render () {
    let avatar = this.context.user ? this.context.user.firstName || this.context.user.email || ' ' : ' '

    return (
      <header>
        <div className='flex-row wrapper'>
          <div className='flex-col logo logo-row'>
            <span className="flex-row">
            <span className='logo logo-row'>
                <span className="flex-row">
                  <span className="logo-icon flex-col"><img alt="CLPS logo" src="/img/logoIcon.png"></img></span> 
                  <span className="logo-red flex-col">CLPS</span>
                </span>
              </span>
               
            </span>
          </div>

          <div className='flex-col'>
            { this.context.user ?
              <div className="flex-row">
                <Link onClick={this.handleClose} to="/learning"><div className="flex-col need-help">Learning Center</div></Link>
                <div className="flex-col need-help" onMouseDown={this.handleClickOpen}>Need Help?</div>
                { this.context.user ?
                  <div className="flex-col avatar"><Avatar>{avatar.slice(0,1)}</Avatar></div>
                : null }
                <Link to="/logout"><div className='flex-col btn-logout'>LOGOUT</div></Link>
              </div>
            :
              null
            }
          </div>
      
        </div>

        <Dialog open={this.state.openSupportDialog} onClose={this.handleClose} aria-labelledby="simple-dialog-title">
          <DialogTitle id="dialog-title">Need Help?</DialogTitle>
            <div className="support-dialog">
              <div className="support-dialog-email">
                  Email us: <a href="mailto:support@clpsconsultants.com">support@clpsconsultants.com</a>
              </div>              
            </div>
        </Dialog>

      </header>
    )
  }
}

export default Header;