export default class userService {
  get() {
    return fetch('/api/user/get', { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      if (response.redirected) {
        window.location.href = response.url;
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getTapAppLogin() {
    return fetch('/api/user/getTapAppLogin', { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getSectionComplete(){
    return fetch('/api/user/getSectionComplete', { 
      method: 'GET', 
      mode: 'cors', 
      credentials: 'include'    
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  update(data){
    return fetch('/api/user/update', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include', 
        body: JSON.stringify(data),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      })    
      .then(response => { 
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json(); 
      })
      .catch( (error) => {
        console.log(error);
        throw Error(error);
      })
  }
}