import React from 'react'
import * as Sentry from '@sentry/browser';
import loginService from '../services/login';
import { Paper, Button, TextField, Icon } from '@mui/material';

import { createBrowserHistory as createHistory } from 'history'
import AppContext from '../context/app'

class ChangePassword extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { params: props.match.params }
    this.loginService = new loginService();
    this.handleChange = this.handleChange.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.history = createHistory();
  }

  componentDidMount(){

  }

  render () {
    return (
      <div id='changePassword'>
        <div className='headlineOne'>Change Password</div>
        <div className='box'>
          <form onSubmit={this.handleChangePassword}>
              <Paper elevation={1}>
                  <div className="section">
                      <TextField className="input-field" name='password' value={this.state.password} error={this.state.passwordError} helperText={'Password must be strong (at least 8 characters, one uppercase character, one lowercase, one number and one symbol)'} autoComplete="current-password" type="password" label="Password" placeholder="Password" required onChange={this.handleChange} />
                  </div>

                  <div className="section">
                      <TextField className="input-field" name='password2' value={this.state.password2} error={this.state.password2Error} autoComplete="current-password" type="password" label="Confirm Password" placeholder="Confirm Password" required onChange={this.handleChange} />
                  </div>
              </Paper>

              <div className="actions">
                  <Button type="submit" variant="outlined"><Icon className="fa fa-save" /> Save</Button>
              </div>
          </form>
        </div>
      </div>
    )
  }

  handleChange(event) {
    let changeObject = {};
    changeObject[event.target.name] = event.target.value;
    this.setState(changeObject);
  }


  async handleChangePassword(event) {
    event.preventDefault();

    let passwordRegEx = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/
    if (!passwordRegEx.test(this.state.password)) {
      this.setState({passwordError: true})
      return
    }

    if (this.state.password !== this.state.password2) {
        this.setState({password2Error: true})
        return
      }

    try {
      await this.loginService.updatePassword(this.state.password, this.state.params.forgotPasswordToken)
      this.context.toastSuccess('Password updated')
      this.props.history.push('/login');
    }
    catch(err) {
      console.log(err);
      this.context.toastError('There was an error changing your password')
      Sentry.captureException(err);
    }
  }

}

export default ChangePassword
