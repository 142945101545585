import React from 'react';
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import { createBrowserHistory } from 'history'

import AppError from '../components/AppError'
import Message from '../components/Message'
import Header from '../components/Header';
import CookiePolicy from '../components/CookiePolicy';
import userService from '../services/user';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import ga from 'react-ga';

import '../css/app.css'

import Login from './Login';
import ForgotPassword from './ForgotPassword'
import ChangePassword from './ChangePassword'
import VerifyUser from './VerifyUser'
import VerifyEmail from './VerifyEmail'

import Home from './Home';
import Register from './Register';
import Learning from './Learning';
import AppContext from '../context/app'
import oauth2Service from '../services/oauth2';
const oauth2ServiceInstance = new oauth2Service();

const history = createBrowserHistory();

class App extends React.Component {
  static contextType = AppContext
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props)
    this.state = {}
    this.history = history
  }

  componentDidMount () {
    if(navigator.userAgent.indexOf('MSIE')!== -1 || navigator.appVersion.indexOf('Trident/') > -1){
      this.setState({ incompatibleBrowser: true })
      return;
    }

    //Go get who is logged in when we need to
    const location = history.location

    //These pages do not require auth to complete
    const unAuthedPages = ['/login','/forgotPassword','/verifyEmail', '/verifyUser'];

    // Listen for changes to the current location
    history.listen(location => {
      //Log in Analytics
      ga.set({ page: location.pathname });
      ga.pageview(location.pathname);

      //security
      if (!this.props.cookies.get('clps') && location.pathname && !unAuthedPages.find( (unAuthedPage) => { return location.pathname.startsWith(unAuthedPage) }))
        history.push('/login', { search: location.state ? location.state.search : null });
    })

    //Default page
    if (!this.props.cookies.get('clps')) {
      if (!unAuthedPages.find( (unAuthedPage) => { return location.pathname.startsWith(unAuthedPage) }))
        history.push('/login', { search: location.search});
    }
    else {
      //load up data and goto default page
      console.log('loading user', this.props.cookies.get('clps'))
      if (!unAuthedPages.find( (unAuthedPage) => { return location.pathname.startsWith(unAuthedPage) })){
        new userService().get()
        .then( (user) => {
          if (!user){
            window.location = 'https://login.clpsconsultants.com/logout'
            return
          }

          console.log('logged in as: ', user.userId)
          ga.set({ user: user.userId });

          this.context.updateUser(user)
        })
        .catch( (err) => {
          console.log(err);
          history.push('/login');
        })

        if (location.pathname === '/') {
          history.push('/home', { search: window.location.search});
        }
      }
    }
}


  render () {
    return (
      <Router>
        <div>
          <Message></Message>

          { !this.state.incompatibleBrowser ?
            <Header/>
          :
            null
          }

          <section className="content">
            { this.state.incompatibleBrowser ?
              <div className="incompatibleBrowser">
                You are using an outdated browser.  Please use Chrome, Safari, Firefox or Edge.
              </div>
            :
              <div>
                 <AppError>
                    <section className="main-container no-sidebar">
                        <Switch>
                              <Route path="/login/oauth2/:provider" render={(props) => {oauth2ServiceInstance.login(props.match.params.provider)(props);return null;}}/>
                              <Route path="/login/:loginToken?" component={Login}/>
                              <Route path="/forgotPassword" component={ForgotPassword}/>
                              <Route path="/changePassword/:forgotPasswordToken" component={ChangePassword}/>
                              <Route path="/logout" render={(props) => (<Login {...props} logout={true} />)}/>
                              <Route path="/verifyUser/:verifyUserToken?" component={VerifyUser}/>
                              <Route path="/verifyEmail/:verifyEmailToken?" component={VerifyEmail}/>
                              <Route path="/register" component={Register}/>
                              <Route path="/home" component={Home}/>
                              <Route path="/learning" component={Learning}/>
                              <Route path="/" component={Home}/>
                        </Switch>
                    </section>
                  </AppError>
              </div>
            }
          </section>
          <CookiePolicy/>
        </div>
      </Router>
    )
  }
}

export default withCookies(App)
