export default class loginService {
  loginToken(loginToken) {
    return fetch('/api/auth/loginToken',
      { method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({loginToken}),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .catch( (error) => {
        console.log(error);
        throw Error(error);
      })
  }

  sendLoginToken(data) {
    return fetch('/api/auth/sendLoginToken',
      { method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify(data),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .catch( (error) => {
        console.log(error);
        throw Error(error);
      })
  }

  authenticate(email, password) {
    return fetch('/api/auth/authenticate',
      { method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({email, password}),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .catch( (error) => {
        console.log(error);
        throw Error(error);
      })
  }

  forgotPassword(email) {
    return fetch('/api/auth/forgotPassword',
      { method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({email}),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .catch( (error) => {
        console.log(error);
        throw Error(error);
      })
  }

  updatePassword(password, forgotPasswordToken) {
    return fetch('/api/auth/updatePassword',
      { method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify({password, forgotPasswordToken}),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .catch( (error) => {
        console.log(error);
        throw Error(error);
      })
  }

  logout() {
    return fetch('/api/auth/logout',
      { method: 'GET',
        mode: 'cors',
        credentials: 'include',
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.ok
      })
      .catch( (error) => {
        console.log(error);
        throw Error(error);
      })
  }

  verifyEmailToken(verifyEmailToken) {
    return fetch('/api/auth/verifyEmailToken',
    { method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({verifyEmailToken}),
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok
    })
    .catch( (error) => {
      console.log(error);
      throw Error(error);
    })
  }

  sendVerifyEmailToken(email, returnUrl) {
    return fetch('/api/auth/sendVerifyEmailToken',
    { method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({email, returnUrl}),
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok
    })
    .catch( (error) => {
      console.log(error);
      throw Error(error);
    })
  }

  verifyUserToken(verifyUserToken) {
    return fetch('/api/auth/verifyUserToken',
    { method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({verifyUserToken}),
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok;
    })
    .catch( (error) => {
      console.log(error);
      throw Error(error);
    })
  }

  sendVerifyUserToken(returnUrl) {
    return fetch('/api/auth/sendVerifyUserToken',
    { method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({returnUrl}),
      json: true,
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok
    })
    .catch( (error) => {
      console.log(error);
      throw Error(error);
    })
  }
}
