import React, { useState, useEffect, useContext } from 'react';
import { Button, Icon } from '@mui/material';
import LocalStorageService from '../services/localstorage';



export default function CookiePolicy(props) {
  const [readCookiePolicy, setReadCookiePolicy] = useState(true)

  useEffect( () => {
    if(!new LocalStorageService().get('clpsCookiePolicy'))
      setReadCookiePolicy(false)
  },[])


  let handleCookiePolicy = (e) => {
    new LocalStorageService().set('clpsCookiePolicy', true)
    setReadCookiePolicy(true)
  }

  return (
    <div id="cookie-policy">
      { !(readCookiePolicy) ?
        <div className="flex-row cookie-policy">
          <div className='flex-col cookie-policy-text'>CLPS uses cookies to improve site functionality, provide you with a better browsing experience, and to collect information regarding site usage and performance.  By using this Site or clicking on "OK", you consent to the use of cookies and the <a target="_blank" href="/Privacy Policy CLPS Software Solutions.pdf">Privacy Policy</a></div>
          <div className='flex-col'>
            <Button onClick={handleCookiePolicy} variant="outlined"><Icon className="fa fa-check" /> Ok</Button>
          </div>
        </div>
      : null }
    </div>
  )
}

