import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import AppContext from '../context/app'
import { Paper, Button, TextField, Icon } from '@mui/material';

import loginService from '../services/login';

import * as Sentry from '@sentry/browser';



export default function Login(props) {
  const context = useContext(AppContext)
  let { loginToken } = useParams()
  if(loginToken)
    loginToken = loginToken.replace(' [login.clpsconsultants.com]','')

//  const [loginTokenRedirect, setLoginTokenRedirect] = useState()
  const [sendLoginLinkError, setSendLoginLinkError] = useState()
  const [authenticationError, setAuthenticationError] = useState()
  const [sendLoginLinkSuccess, setSendLoginLinkSuccess] = useState()
  const [authEmail, setAuthEmail] = useState()
  const [password, setPassword] = useState()
  const [sendAuthEmail, setSendAuthEmail] = useState()

  useEffect( () => {
    window.scroll(0,0)
    const load = async () => {
      if (props.logout){
        try {
          await new loginService().logout()
          context.updateUser(null)
        }
        catch(err){
          console.log(err)
          context.toastError('There was an error logging out')
          Sentry.captureException(err);
        }
      }

      if (loginToken) {
        //some email clients add the domain and will not work for copy.

        try {
          let loginResult = await new loginService().loginToken(loginToken)
          let loginErrorMap = {
            invalidToken: "Your login token was invalid.  Please copy the entire login link",
            subscriptionNotFound: 'Please contact your administrator to receive an invitation.',
            subscriptionExpired: 'Your subscription has expired.',
            loginTokenExpired: 'Your login token has expired.  We have emailed you a new access link',
            userNotFound: 'Please contact your administrator to receive an invitation.',
            lockedAccount: 'Your account has been temporarily locked'
          }

          //if user was found, login
          if (!loginResult.loginError) {
            setSendLoginLinkError(false)

            if (loginResult.redirect)
              window.location = loginResult.redirect
          }
          else {
            //there was an error
            let sendLoginLinkError = loginErrorMap[loginResult.loginError];
            setSendLoginLinkError(sendLoginLinkError)
          }
        }
        catch(err){
          console.log(err)
          context.toastError('There was an error with your login link, please click the link or copy and paste the entire link.')
          Sentry.captureException(err);
        }
      }
    }
    load()

  }, [])


  const handleAuthenticate = async(event) => {
    event.preventDefault();

    try {
      let authenticateResult = await new loginService().authenticate(authEmail, password)
      let authenticationErrorMap = {
        subscriptionExpired: 'Your subscription has expired.',
        userNotFound: 'Email and password combination is incorrect.',
        lockedAccount: 'Your account has been temporarily locked'
      }

      //if user was found, login
      if (!authenticateResult.authenticationError) {
        window.location = '/'
      }
      else {
        //there was an error
        let authenticationError = authenticationErrorMap[authenticateResult.authenticationError];
        setAuthenticationError(authenticationError)
      }
    }
    catch (err) {
      console.log(err);
      context.toastError('There was an error logging in')
      Sentry.captureException(err);
    }
  }

  const handleSendLoginLink = async (event) => {
    event.preventDefault();

    try {
      let sendLoginLinkResult = await new loginService().sendLoginToken({ email: sendAuthEmail})
      let sendLoginLinkErrorMap = {
        subscriptionNotFound: 'You do not have a subscription.',
        subscriptionExpired: 'Your subscription has expired.',
        userNotFound: 'Please contact your administrator to receive an invitation.',
        lockedAccount: 'Your account has been temporarily locked'
      }

      //if user was found, login
      if (!sendLoginLinkResult.sendLoginLinkError) {
       setSendLoginLinkSuccess(true)
      }
      else {
        //there was an error
        let sendLoginLinkError = sendLoginLinkErrorMap[sendLoginLinkResult.sendLoginLinkError];
        setSendLoginLinkError(sendLoginLinkError)
      }
      window.scroll(0,0)
    }
    catch (err) {
      console.log(err);
      context.toastError('There was an error sending your invite link')
      Sentry.captureException(err);
    }
  }

    return (
      <div id='login'>
        <section className='login-outerholder'>
          <section className='login-holder'>
            <div>
                <div className='login-box'>
                  { !sendLoginLinkSuccess ?
                    <div className="login-options">
                      <div className="login-option">
                        <form onSubmit={handleAuthenticate}>
                          <Paper elevation={1}>
                            <div className="section">
                              <div>
                                <TextField className="input-field" name='authEmail' type="email" maxLength="320"
                                           label="Email" value={authEmail ?? ''} required
                                           onChange={(e) => setAuthEmail(e.target.value)}/>
                              </div>
                              <div className="section">
                                <TextField className="input-field" name='password' type="password" maxLength="25"
                                           label="Password" value={password ?? ''} required
                                           onChange={(e) => setPassword(e.target.value)}/>
                              </div>
                            </div>
                          </Paper>

                          <div className="btn-holder">
                            <Button type="submit" variant="outlined"><Icon
                              className="fa fa-arrow-right"/> Login</Button>
                          </div>
                          <div>
                            <Link to="/forgotPassword"><Button className="button-link" type="button" variant="link">Forgot
                              Password</Button></Link>
                          </div>
                        </form>

                        {authenticationError ?
                          <div>
                            <div className="errorMessage">{authenticationError}</div>
                          </div>
                          : null}
                      </div>

                      {/* <div className="oauth2-block">
                        <a
                          className="link"
                          href={"https://accounts.google.com/o/oauth2/auth?response_type=code&response_mode=query&scope=openid%20email&client_id=" + process.env.REACT_APP_OAUTH2_GOOGLE_CLIENT_ID + "&redirect_uri=" + process.env.REACT_APP_APP_URL + '/' + process.env.REACT_APP_OAUTH2_GOOGLE_REDIRECT_PATH}>
                          <img src="/img/logo-google.svg" alt=""/>
                        </a>
                        <a
                          className="link"
                          href={"https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&response_mode=query&scope=openid%20profile&client_id=" + process.env.REACT_APP_OAUTH2_MICROSOFT_CLIENT_ID + "&redirect_uri=" + encodeURIComponent(process.env.REACT_APP_APP_URL + '/' + process.env.REACT_APP_OAUTH2_MICROSOFT_REDIRECT_PATH)}>
                          <img src="/img/logo-microsoft.svg" alt=""/>
                        </a>
                      </div> */}

                      <div className="or">
                        OR
                      </div>

                      <div className="login-option">
                        <form onSubmit={handleSendLoginLink}>
                          <Paper elevation={1}>
                            <div className="section">
                              <TextField className="input-field" id="login-email" name='email' type="email"
                                         maxLength="320" label="Email" value={sendAuthEmail ?? ''} required
                                         onChange={(e) => setSendAuthEmail(e.target.value)}/>
                            </div>
                          </Paper>

                          <div className="btn-holder">
                            <Button type="submit" variant="outlined"><Icon className="fa fa-envelope"/> Send Login Link</Button>
                          </div>
                        </form>

                        {sendLoginLinkError ?
                          <div>
                            <div className="errorMessage">{sendLoginLinkError}</div>
                          </div>
                          : null}
                      </div>
                    </div>
                    :
                    <div>
                      {sendLoginLinkSuccess ?
                        <div className="successMessage">An email has been sent to you with your login link.</div>
                        : null}
                    </div>
                  }
                </div>
            </div>
          </section>
        </section>

      </div>
    )
}
