import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AppContext from '../context/app'
import { Paper, Button, MenuItem, TextField, Icon, InputLabel, FormControl } from '@mui/material';

import institutionService from '../services/institution';

import * as Sentry from '@sentry/browser';
import userService from '../services/user';

export default function Register(props) {
  const context = useContext(AppContext)
  const history = useHistory();
  const [passwordError, setPasswordError] = useState()
  const [password, setPassword] = useState()
  
  const [user, setUser] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {}
  );

  useEffect( () => {
    window.scroll(0,0)

    if (!context.user) {
      history.push('/home')
      return
    }

    async function fetchData() {
      //check if police
      let institutions = await new institutionService().getInstitution()
      user.isPolice = institutions.some( i => i.type === 'police')

      setUser(context.user)      
    }

    try {
      fetchData();
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  useEffect( () => {
    context.updateUser(user)  
  },[user])

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      let passwordRegEx = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/
      if (!passwordRegEx.test(password)) {
        setPasswordError(true)
        return
      }
  
      await new userService().update({...user, password})
      setUser(await new userService().get())
      props.history.push('/home');
    }
    catch(err){
      console.log(err)
      context.toastError('There was an error registering your account')
      Sentry.captureException(err);

    }
  }

  return (
    <div id='register'>
      <section className='login-outerholder'>
          { user.email ?
          <section className='login-holder'>                
            <div> 
             <div className='headlineOne'>Please Register</div>
             <form onSubmit={handleRegister}>
              <Paper elevation={1}>
                  <div className="section">
                    { user.email }
                  </div>

                  <div className="section">
                      <TextField className="input-field" name='firstName' value={user.firstName} maxLength="45" type="text" label="First Name" required onChange={(e) => { user.firstName = e.target.value; setUser(user)} }/>
                  </div>

                  <div className="section">
                      <TextField className="input-field"  id="register-last-name" name='lastName' value={user.lastName} maxLength="45" type="text" label="Last Name" required onChange={(e) => { user.lastName = e.target.value; setUser(user)} } />
                  </div>

                  <div className="section">
                      <FormControl required className="input-field">
                          <TextField select label="Title" required value={user.title || ''} onChange={(e) => { user.title = e.target.value; setUser(user)} } >
                            <MenuItem value="Assistant Dean">Assistant Dean</MenuItem>
                            <MenuItem value="Assistant Principal">Assistant Principal</MenuItem>
                            <MenuItem value="Assistant Superintendent">Assistant Superintendent</MenuItem>
                            <MenuItem value="Captain">Captain</MenuItem>
                            <MenuItem value="Chief">Chief</MenuItem>
                            <MenuItem value="Commander">Commander</MenuItem>
                            <MenuItem value="Coordinator">Coordinator</MenuItem>
                            <MenuItem value="Counselor">Counselor</MenuItem>
                            <MenuItem value="Dean">Dean</MenuItem>
                            <MenuItem value="Deputy">Deputy</MenuItem>
                            <MenuItem value="Detective">Detective</MenuItem>
                            <MenuItem value="Director">Director</MenuItem>
                            <MenuItem value="Fire Inspector">Fire Inspector</MenuItem>
                            <MenuItem value="Fire Marshal">Fire Marshal</MenuItem>
                            <MenuItem value="Insurance Representative">Insurance Representative</MenuItem>
                            <MenuItem value="Investigator">Investigator</MenuItem>
                            <MenuItem value="Law Enforement">Law Enforement</MenuItem>
                            <MenuItem value="Lieutenant">Lieutenant</MenuItem>
                            <MenuItem value="Nurse">Nurse</MenuItem>
                            <MenuItem value="Mental Health Professional">Mental Health Professional</MenuItem>
                            <MenuItem value="Police Officer">Police Officer</MenuItem>
                            <MenuItem value="Principal">Principal</MenuItem>
                            <MenuItem value="Psychologist">Psychologist</MenuItem>
                            <MenuItem value="School Resource Officer">School Resource Officer</MenuItem>
                            <MenuItem value="School Resource Deputy">School Resource Deputy</MenuItem>
                            <MenuItem value="Security">Security</MenuItem>
                            <MenuItem value="Sergeant">Sergeant</MenuItem>
                            <MenuItem value="Sheriff">Sheriff</MenuItem>
                            <MenuItem value="Social Worker">Social Worker</MenuItem>
                            <MenuItem value="Superintendent">Superintendent</MenuItem>
                            <MenuItem value="Teacher">Teacher</MenuItem>
                            <MenuItem value="Teacher Assistant">Teacher Assistant</MenuItem>
                            <MenuItem value="Threat Management Coordinator">Threat Management Coordinator</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </TextField>
                      </FormControl>
                  </div>

                  { user.isPolice ?
                      <section>
                        <div className="section">
                          <TextField className="input-field" name='enforcementOfficialPhone' value={user.enforcementOfficialPhone} maxLength="10" type="text" label="Phone" required onChange={(e) => { user.enforcementOfficialPhone = e.target.value; setUser(user)} } />
                        </div>

                        <div className="section">
                          <TextField className="input-field" name='enforcementOfficialAddress' value={user.enforcementOfficialAddress} maxLength="100" type="text" label="Address" required onChange={(e) => { user.enforcementOfficialAddress = e.target.value; setUser(user)} } />
                        </div>
                      </section>
                  : null }

                  <div className="section">
                      <TextField required className="input-field" name="password" error={passwordError} value={password} helperText={'Password must be strong (at least 8 characters, one uppercase character, one lowercase, one number and one symbol)'} autoComplete="current-password" type="password" maxLength="25" label="Password" onChange={(e) => { setPassword(e.target.value) } } />
                  </div>


              </Paper>

              <div className="actions">
                  <Button type="submit" variant="outlined"><Icon className="fa fa-arrow-right" /> Register</Button>
              </div>
            </form>
            </div>
          </section>
          : null }
      </section>
    </div>
  )
}